<template>
  <DeviceCheckFrame v-if="callState === 'check'" :participant="localParticipant" :devices="devices" theme="generic"
    @cameraChange="onCameraChange" @microphoneChange="onMicrophoneChange" @speakerChange="onSpeakerChange"
    @joinCall="prejoinCall" @runNetworkCheck="runNetworkCheck" />

  <ClassroomEarly v-else-if="callState === 'early'" :theme="theme" :time-to-start="timeToStart" :join-time="joinTime"
    @joinCall="prejoinCall" />

  <JoinFrame v-else-if="callState === 'prejoin' && localParticipant" :participant="localParticipant" :devices="devices"
    :theme="theme" :lesson-data="lessonData" @cameraChange="onCameraChange" @microphoneChange="onMicrophoneChange"
    @videoClick="onVideoClick" @muteClick="onParticipantMuteClick" @audioClick="onAudioClick" @joinCall="joinCall"
    @leaveClick="onLeaveClick" />

  <ClassroomLobby v-else-if="callState === 'lobby'" :theme="theme" :time-to-start="timeToStart" />

  <CallFrame v-else-if="callState === 'active'" :participants="participants" :waiting-participants="waitingParticipants"
    :expected-participants="expectedParticipants" :theme="theme" :local-participant="localParticipant"
    :enable-people="enablePeople" :enable-chat="classroomSettings.enableChat"
    :enable-screen-share="classroomSettings.enableScreenShare" :platform="platform" :devices="devices"
    :messages="chatMessages" :screen-shares="screenShares" :screen-share-settings="screenShareSettings"
    :classroom-settings="classroomSettings" :lesson-data="lessonData" @videoClick="onVideoClick"
    @audioClick="onAudioClick" @screenShareClick="onScreenShareClick" @cameraChange="onCameraChange"
    @microphoneChange="onMicrophoneChange" @speakerChange="onSpeakerChange"
    @participantHideClick="onParticipantHideClick" @participantMuteClick="onParticipantMuteClick"
    @sendMessage="sendChat" @leaveClick="onLeaveClick" @admitClick="onAdmitClick" @denyClick="onDenyClick"
    @emojiClick="onEmojiClick" @eject="onEjectClick" @handClick="onHandClick" @pinScreenShare="onPinScreenShare"
    @stopParticipantScreenShare="onStopParticipantScreenShare" @toggleChat="onToggleChat"
    @deleteChatMessage="onDeleteChatMessage" @dismissHand="onDismissHand" @toggleBackground="onToggleBackground"
    @challenge-changed="onChallengeChanged" />

  <CallEnded v-else-if="callState === 'ended' || callState === 'denied'" :denied="callState === 'denied'" :theme="theme"
    :lesson-uuid="lessonUuid" :student-uuid="studentUuid" :teacher-uuid="teacherUuid" :show-journey="showJourney"
    @join-click="prejoinCall" />

  <JoinLinkInvalid v-else-if="callState === 'invalid'" />
  <Disconnected v-else-if="callState === 'disconnected'" />

  <ClassroomWaiting v-else :theme="theme" />
  <ModalMessage ref="modalMessage" />
</template>

<script setup>

import env from '@/env.js';
import daily from "@daily-co/daily-js";
import apiHelpers from '@/scripts/helpers/apiHelpers.js';
import domHelpers from '@/scripts/helpers/domHelpers.js';
import logger from '@/scripts/logger.js';
import CallFrame from '@/components/classroom/CallFrame.vue';
import CallEnded from '@/components/classroom/CallEnded.vue';
import JoinLinkInvalid from '@/components/classroom/JoinLinkInvalid.vue';
import Disconnected from '@/components/classroom/Disconnected.vue';
import ClassroomLobby from '@/components/classroom/ClassroomLobby.vue';
import ClassroomEarly from '@/components/classroom/ClassroomEarly.vue';
import DeviceCheckFrame from '@/components/classroom/deviceCheck/DeviceCheckFrame.vue';
import JoinFrame from '@/components/classroom/JoinFrame.vue';
import ClassroomWaiting from '@/components/classroom/ClassroomWaiting';
import ModalMessage from "@/components/ModalMessage.vue";
import { ref, onMounted, onUnmounted, computed, provide, toRaw } from 'vue';
import { useRoute } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import { CallStatus } from '@/logic/callStatus';
import _ from 'lodash';

var call,
  recording = false,
  adminToken,
  emojiTimeout,
  callStatus = new CallStatus(),
  sendQualities = ['low', 'medium', 'high'],
  defaultSendQuality = 2,
  defaultReceiveLayers = 2,
  callListeners = {
    'cpu-load-change': onCpuLoadChanged,
    'participant-joined': onParticipantJoined,
    'participant-updated': onParticipantUpdated,
    'participant-left': onParticipantLeft,
    'joined-meeting': onJoinedMeeting,
    'local-screen-share-stopped': onScreenShareEnded,
    'local-screen-share-started': onScreenShareStarted,
    'network-quality-change': onNetworkQualityChanged,
    'error': onCallError,
    'nonfatal-error': onNonFatalError,
    'left-meeting': onLeftMeeting,
    // 'camera-error': onDeviceError,
    'app-message': processAppMessage,
    'local-audio-level': logMicrophoneLevel,
    'recording-error': handleRecordingError,
    'recording-started': onStartRecording,
    'waiting-participant-added': onWaitingParticipantsUpdated,
    'waiting-participant-removed': onWaitingParticipantsUpdated
  };

function onJoinedMeeting() {
  setTimeout(() => {
    updateCallPerformanceSettings();
  }, 1000);
}

const roomUrl = ref(null),
  name = ref(null),
  virtualBackgroundUrl = ref(null),
  theme = ref(null),
  timeToStart = ref(null),
  backgroundStatus = ref('off'),
  chatMessages = ref([]),
  participants = ref(null),
  waitingParticipants = ref(null),
  enablePeople = ref(false),
  devices = ref(null),
  isTeacher = ref(false),
  platform = ref(null),
  route = useRoute(),
  callState = ref('launch'),
  hasLesson = ref(false),
  showJourney = ref(false),
  lessonUuid = ref(null),
  studentUuid = ref(null),
  teacherUuid = ref(null),
  localMicrophoneLevel = ref(0),
  { cookies } = useCookies(),
  classroomSettings = ref({ enableChat: true, enableScreenShare: true }),
  screenShareSettings = ref({ pinned: null, pinnedWidth: null }),
  devicesChecked = ref(false),
  modalMessage = ref(null),
  lessonData = ref(null),
  joinTime = ref(15 * 60),  // How many seconds before lesson should student be able to access the lobby?
  expectedParticipants = ref(null);

provide('localMicrophoneLevel', localMicrophoneLevel);
provide('hasLesson', hasLesson);
provide('isTeacher', isTeacher);

onMounted(async () => {
  lessonUuid.value = isTest() ? 'test' : route.params.lessonUuid;
  studentUuid.value = isTest('Student') ? 'test' : route.params.studentUuid;
  teacherUuid.value = route.params.teacherUuid;
  hasLesson.value = lessonUuid.value ? true : false;

  launchCall();

  // Listen to online/offline events
  window.addEventListener('offline', onOffline);
  window.addEventListener('online', onOnline)
});

onUnmounted(() => {
  if (!call) return;

  removeCallListeners();

  // Listen to online/offline events
  window.removeEventListener('offline', onOffline)
  window.removeEventListener('online', onOnline)
});

function isTest(type = '') {
  return route.name.indexOf(`ClassroomTest${type}`) === 0;
}

const screenShares = computed(() => {
  return getScreenShares();
});

const localParticipant = computed(() => {
  return getLocalParticipant();
});

function getLocalParticipant() {
  return _.find(participants.value, (p) => { return p.local });
}

async function launchCall() {
  // If just a check
  if (!lessonUuid.value && !studentUuid.value) {
    runDeviceCheck();
  }
  else {
    prejoinCall();
  }
}

function onOnline() {
  setUserData('online', true);
}

function onOffline() {
  setUserData('online', false);
}

function needsDeviceCheck() {
  // We need a device check if one or more device lists are empty, or if we haven't had a recent device check
  return !devicesChecked.value &&
    (
      !devices.value ||
      !devices.value.camera ||
      devices.value.camera.length === 0 ||
      !devices.value.microphone ||
      devices.value.microphone.length === 0 ||
      !getLastDeviceCheckResults()
    );
}

async function runDeviceCheck() {
  await getDevices();
  await createCall();
  callState.value = 'check';
  devicesChecked.value = true;
  recordLessonEvent('deviceCheck');
}

function onPinScreenShare(data) {
  // Pin if different, unpin if it's the same participant (toggle)
  screenShareSettings.value.pinned = screenShareSettings.value.pinned === data.participant.session_id ? null : data.participant.session_id;
  screenShareSettings.value.pinnedWidth = data.width;
  sendScreenshareSettings();
}

function onStopParticipantScreenShare(participant) {
  updateParticipant(participant, { setScreenShare: false });
}

function onToggleChat() {
  classroomSettings.value.enableChat = !classroomSettings.value.enableChat;
  sendClassroomSettings()
}

function sendScreenshareSettings(participant) {
  sendAppMessage('screenShareSettings', toRaw(screenShareSettings.value), participant);
}

function sendClassroomSettings(participant) {
  sendAppMessage('classroomSettings', toRaw(classroomSettings.value), participant);
}

function sendAppMessage(type, content, participant = null) {
  var data;

  try {
    if (hasAccessLevel('full')) {
      data = { type: type, content: content };
      call.sendAppMessage(data, participant ? participant.session_id : '*');

      recordLessonEvent('sendAppMessage', data);
    }
  }
  catch (err) {
    logger.error(err, JSON.stringify(data));
  }
}

function hasAccessLevel(level) {
  return call.accessState()?.access.level === level;
}

async function prejoinCall(params) {
  var callData,
    data,
    deviceCheckResults,
    originalLessonUuid = lessonUuid.value;

  callState.value = null;

  callData = await fetchCallData();
  if (callData && !callData.error) {
    if (callData.redirect) {
      // Redirect to Zoom 
      window.location.href = callData.redirect;
    }
    else {
      // Otherwise process all the call info and take them to the lobby
      hasLesson.value = true;
      await getDevices();
      if (needsDeviceCheck()) {
        runDeviceCheck();
      }
      else {
        adminToken = callData.adminToken;
        theme.value = callData.theme;
        timeToStart.value = Math.max(0, callData.timeToStart);
        showJourney.value = callData.showJourney ? true : false;

        // If student is too early, show the early screen
        if (!adminToken && timeToStart.value > joinTime.value) {
          callState.value = 'early';
          recordLessonEvent('early', { timeToStart: timeToStart.value });
        }
        else {
          await createCall();
          if (callData.lessonUuid) {
            lessonUuid.value = callData.lessonUuid
          }

          if (lessonUuid.value) {
            name.value = callData.nickname || callData.name;
            roomUrl.value = callData.url;

            if (callData.platformUrl) {
              platform.value = {
                username: callData.username,
                password: callData.password,
                url: callData.platformUrl,
                name: callData.platformName
              };
            }

            if (adminToken) {
              isTeacher.value = true;

              expectedParticipants.value = shapeExpectedParticipants(callData.expectedStudents);
            }

            virtualBackgroundUrl.value = callData.virtualBackgroundUrl;
            lessonData.value = {
              time: callData.time,
              uuid: lessonUuid.value,
              date: callData.dateString,
              productName: callData.productName,
              contentType: callData.contentType,
              weekNumber: callData.weekNumber,
              logins: {}
            };

            // if (virtualBackgroundUrl.value) {
            //   addBackgroundEffects();
            // }

            await call.preAuth(getCallOptions());
            setFeaturePermissions();
            callState.value = 'prejoin';

            if (params && params.deviceCheckResults) {
              deviceCheckResults = params.deviceCheckResults;
            }
            else {
              deviceCheckResults = getLastDeviceCheckResults();
            }

            if (deviceCheckResults) {
              data = data || {};
              data.deviceCheckResults = deviceCheckResults;
            }

            recordLessonEvent('prejoined', data);
          }
          else {
            callState.value = 'invalid';
            logger.error(`No lessonUuid (callData: ${JSON.stringify(callData)}, lessonUuid: ${originalLessonUuid}, studentUuid: ${studentUuid.value})`);
          }
        }
      }
    }
  }
  else if (callData && callData?.error && callData?.error.indexOf('Network Error') >= 0) {
    callState.value = 'disconnected';
  }
  else {
    callState.value = 'invalid';
  }
}

function shapeExpectedParticipants(students) {
  var result;

  if (students) {
    result = [];
    for (var student of students) {
      result.push({
        user_name: `${student.firstName} ${student.lastName}`,
        userData: {
          studentUuid: student.uuid,
          challengeBadges: student.challengeBadges
        }
      })
    }
  }

  return result;
}

function getLastDeviceCheckResults() {
  var result;

  result = cookies.get('deviceCheck');

  return result;
}

async function startCamera() {
  await call.startCamera();

  setActiveInputDevices(await call.getInputDevices());
  checkDefaultDevices();
}

async function goToLobby() {
  var granted;

  callState.value = 'lobby';

  granted = await call.requestAccess({
    name: name.value,
    access: {
      level: 'full',
    },
  });

  if (granted) {
    for (var participant of participants.value) {
      if (!participant.local) {
        setTrackSubscription(participant, false, false);
      }
    }

    callState.value = 'active';
    recordLessonEvent('admitted');
  } else {
    onDeniedAccess()
  }
}

function onDeniedAccess() {
  callState.value = 'denied';
  recordLessonEvent('denied');
}

async function joinCall() {
  callState.value = null;
  await call.join(getCallOptions());
  await setCallSettings();

  if (env.dev) {
    addFakeParticipants(2);
  }

  if (isTeacher.value) {
    callState.value = 'active';
  }
  else {
    goToLobby();
  }

  recordLessonEvent('joined');
}

async function createCall() {
  call = daily.getCallInstance();

  if (!call) {
    call = daily.createCallObject();

    if (env.dev) {
      window.dailyCall = call;
    }

    addCallListeners();
    await startCamera();
    call.startLocalAudioLevelObserver(100);
    setUserData();
  }

  refreshParticipants(true);

  return call;
}

function addCallListeners() {
  for (var key in callListeners) {
    call.on(key, callListeners[key]);
  }

  callStatus.on('status-changed', handleCallStatusChange);
}

function removeCallListeners() {
  for (var key in callListeners) {
    call.off(key, callListeners[key]);
  }
}

function getCallOptions() {
  var result;

  result = {
    url: roomUrl.value,
    userName: name.value,
    sendSettings: { video: { maxQuality: sendQualities[defaultSendQuality] } },
    receiveSettings: { video: { layer: defaultReceiveLayers } }
  };

  if (adminToken) {
    result.token = adminToken;
  }

  return result;
}

async function setUserData(key, value) {
  var data,
    participant;

  // If no specific data, set the default user data values
  if (!key) {
    data = { online: true };

    if (teacherUuid.value) {
      data.teacherUuid = teacherUuid.value;
    }

    if (studentUuid.value) {
      data.studentUuid = studentUuid.value;
    }
  }
  // Otherwise, update with specific key/value
  else {
    participant = getLocalParticipant();
    if (participant) {
      data = { ...participant.userData };
      data[key] = value;
    }
  }

  if (data) {
    await updateUserData(data);
  }
}

async function removeUserData(key) {
  var data;

  data = getUserData();
  if (data) {
    data = _.clone(data);

    if (data[key]) {
      delete data[key];
    }

    await updateUserData(data);
  }
}

async function updateUserData(data) {
  var safeData;

  safeData = JSON.parse(JSON.stringify(data))
  await call.setUserData(safeData);
}

function getUserData() {
  return getLocalParticipant()?.userData;
}

async function addFakeParticipants(howMany) {
  for (var i = 0; i < howMany; i++) {
    call.addFakeParticipant();
  }
}

async function setCallSettings() {
  call.updateInputSettings({
    audio: {
      processor: {
        type: 'noise-cancellation',
      },
    },
  });

  call.setSubscribeToTracksAutomatically(false);
}

async function fetchCallData() {
  var endpoint;

  if (lessonUuid.value) {
    if (isTest('Student')) {
      endpoint = `classroom/test/${teacherUuid.value}`;
    }
    else if (isTest('Teacher')) {
      endpoint = `classroom/test/t/${teacherUuid.value}`;
    }
    else {
      // From /classroom link with lessonUuid student/teacher UUID
      endpoint = teacherUuid.value ? `classroom/t/${teacherUuid.value}/${lessonUuid.value}` : `classroom/${studentUuid.value}/${lessonUuid.value}`;
    }
  }
  else {
    // From /join link
    endpoint = `classroom/${studentUuid.value}`;
  }

  return await apiHelpers.get(endpoint, true);
}

function addBackgroundEffects() {
  if (virtualBackgroundUrl.value) {
    setBackgroundImage(virtualBackgroundUrl.value);
  }
  else {
    setBackgroundBlur(0.7);
  }

  backgroundStatus.value = 'on';
}

function removeBackgroundEffects() {
  if (backgroundStatus.value === 'on') {
    call.updateInputSettings({
      video: {
        processor: {
          type: 'none'
        },
      },
    });

    backgroundStatus.value = 'off';
  }
}

function toggleBackground() {
  if (backgroundStatus.value === 'on') {
    removeBackgroundEffects();
    backgroundStatus.value = 'disabled';
  }
  else {
    addBackgroundEffects();
  }

  recordLessonEvent('toggledBackground', { state: backgroundStatus.value });
}

function setBackgroundBlur(strength) {
  if (call) {
    call.updateInputSettings({
      video: {
        processor: {
          type: 'background-blur',
          config: { strength: strength },
        },
      },
    });
  }
}

function setBackgroundImage(url) {
  call.updateInputSettings({
    video: {
      processor: {
        type: 'background-image',
        config: {
          source: url
        },
      },
    },
  });
}

function setFeaturePermissions() {
  enablePeople.value = isTeacher.value;
}

function onParticipantUpdated() {
  var updated,
    existing,
    diff;

  updated = arguments[0].participant;
  refreshParticipants();

  // Find the updated participant in the participants array
  existing = _.find(participants.value, (p) => { return p.session_id === updated.session_id });

  if (existing) {
    // Get list of properties that have changed
    diff = getObjectDiff(existing, updated);

    // Apply the updates to the participant
    for (var key of diff) {
      existing[key] = updated[key];
    }

    // If the pinned user has stopped screensharing, stop the pin
    if (isTeacher.value
      && screenShareSettings.value.pinned == existing.session_id
      && (!existing?.tracks?.screenVideo?.state || existing.tracks.screenVideo.state === 'off')) {
      screenShareSettings.value.pinned = null;
      sendScreenshareSettings();
    }
  }
}

function getObjectDiff(obj1, obj2) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

function onParticipantJoined(action) {
  addParticipant(action.participant);
}

function addParticipant(participant) {
  var isPinned,
    screenShareVideoSubscription,
    screenShareAudioSubscription;

  if (isTeacher.value) {
    sendClassroomSettings(participant);
    sendScreenshareSettings(participant);
    fetchStudentLogins(participant);

    if (!env.dev && !recording) {
      call.startRecording();
      recording = true;
    }
  }

  // Subscribe (or not) to screen share for this new participant
  isPinned = participant.session_id === screenShareSettings.value.pinned;

  screenShareVideoSubscription = (isPinned || isTeacher.value) ? true : false;
  screenShareAudioSubscription = false; // isPinned ? true : isTeacher.value === true ? 'staged' : false;

  setTrackSubscription(participant, screenShareVideoSubscription, screenShareAudioSubscription);

  participants.value.push(participant);
}

async function fetchStudentLogins(participant) {
  var endpoint,
    uuid,
    logins;

  uuid = participant.userData?.studentUuid;
  if (uuid) {
    endpoint = `logins/${uuid}`;
    logins = await apiHelpers.get(endpoint);

    if (logins) {
      lessonData.value.studentLogins = lessonData.value.studentLogins || {};
      lessonData.value.studentLogins[uuid] = logins;
    }
  }
}

function setTrackSubscription(participant, videoSubscription, audioSubscription) {
  // Teacher is always subscribed to all
  if (!participant.local) {
    updateParticipant(participant, { setSubscribedTracks: { audio: true, video: true, screenVideo: videoSubscription, screenAudio: audioSubscription }, });
  }
}

function splitChatMessage(text) {
  var count = 0,
    maxLength = 3000,
    part = '',
    result = [];

  for (var i in text) {
    count++
    part += text[i];
    if (count > maxLength) {
      result.push(part);
      part = '';
      count = 0;
    }
  }

  if (part) {
    result.push(part);
  }

  return result;
}

async function sendChat(text) {
  var content,
    id,
    parts;

  // Split text up if too long
  parts = splitChatMessage(text);

  for (var part of parts) {
    id = (crypto && crypto?.randomUUID) ? crypto.randomUUID() : Date.now();
    content = { text: part, name: name.value, id: id };
    sendAppMessage('sendChatMessage', content);
    content.name += ' (You)';
    addChat(content);
    await recordLessonEvent('chatted', { message: part });
  }
}

function onDeleteChatMessage(id) {
  sendAppMessage('deleteChatMessage', { id: id });
  deleteChatMessage(id);
}

function deleteChatMessage(id) {
  _.remove(chatMessages.value, (c) => { return c.id === id });
  recordLessonEvent('deleteChatMessage', { id: id });
}

// Add chat message to local message array
function processAppMessage(message) {
  var data = message.data;
  switch (data.type) {
    case 'sendChatMessage':
      receiveChatMessage(data.content);
      break;
    case 'deleteChatMessage':
      deleteChatMessage(data.content.id);
      break;
    // case 'stopScreenShare':
    //   stopScreenShare();
    //   break;
    case 'screenShareSettings':
      updateScreenShareSettings(data.content);
      break;
    case 'classroomSettings':
      updateClassroomSettings(data.content);
      break;
    case 'dismissHand':
      dismissHand();
      break;
    case 'toggleBackground':
      toggleBackground();
      break;
  }
}

function updateScreenShareSettings(newSettings) {
  var oldPinned,
    pinnedParticipant,
    newPinned;

  oldPinned = screenShareSettings.value.pinned;
  newPinned = newSettings.pinned;

  // Unsubscribe from unpinned
  if (oldPinned !== newPinned) {
    if (oldPinned) {
      setTrackSubscription({ session_id: oldPinned }, false, false);
    }

    // Subscribe to pinned participant
    if (newPinned) {
      pinnedParticipant = getParticipantBySessionId(newPinned);
      if (pinnedParticipant) {
        setTrackSubscription(pinnedParticipant, true, true);
      }
    }

    setTimeout(() => {
      updateCallPerformanceSettings(callStatus.status.mode);
    }, 1000);
  }

  screenShareSettings.value = newSettings;

  recordLessonEvent('updateScreenShareSettings', newSettings);
}

function getParticipantBySessionId(sessionId) {
  return _.find(participants.value, (p) => { return p.session_id === sessionId });
}

function updateClassroomSettings(newSettings) {
  classroomSettings.value = newSettings;
  if (!classroomSettings.value.enableScreenShare) {
    stopScreenShare();
  }
  recordLessonEvent('updateClassroomSettings', newSettings);
}

function receiveChatMessage(content) {
  addChat(content);
  recordLessonEvent('receiveChatMessage', content);
}

function addChat(content) {
  var text;

  text = domHelpers.embedLinks(domHelpers.htmlEncode(content.text)).replace(/(?:\r\n|\r|\n)/g, '<br>');
  chatMessages.value.push({ content: text, name: content.name, id: content.id })
}

function updateParticipant(participant, settings) {
  var sessionId = participant.session_id;
  call.updateParticipant(sessionId, settings);
}

function getScreenShares(localOnly = false) {
  var shares;

  if (participants.value) {
    shares = participants.value.filter((p) => p.screenVideoTrack && (!localOnly || p.local));
  }

  return shares && shares.length > 0 ? shares : null;
}

function isScreensharing() {
  return getScreenShares(true) ? true : false;
}

async function leaveCall(reason) {
  if (call) {
    recordLessonEvent('left', { reason: reason });

    if (isScreensharing()) {
      await stopScreenShare();
    }

    call.leave().then(() => {
      call.destroy();
    });
  }
  callState.value = 'ended';
  participants.value = null;
}

function logMicrophoneLevel(level) {
  localMicrophoneLevel.value = level.audioLevel;
}

// Get user permission to access devices
async function requestUserPermissions() {
  try {
    await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
  }
  catch (error) {
    recordLessonEvent('device-permission-error', { message: error?.message });
  }
}

// Get list of available devices to show in UI
async function getDevices() {
  var availableDevices,
    cameras,
    microphones,
    speakers;

  try {
    // Create lists of available device types
    availableDevices = navigator.mediaDevices?.enumerateDevices ? await navigator.mediaDevices.enumerateDevices() : null;
    if (!availableDevices || !availableDevices[0]?.label) {
      await requestUserPermissions();
      availableDevices = await navigator.mediaDevices.enumerateDevices();
    }

    cameras = _.filter(availableDevices, (d) => { return d.kind == 'videoinput' && d.deviceId !== '' && d.label !== '' });
    microphones = _.filter(availableDevices, (d) => { return d.kind == 'audioinput' && d.deviceId !== '' && d.label !== '' });
    speakers = _.filter(availableDevices, (d) => { return d.kind == 'audiooutput' && d.deviceId !== '' && d.label !== '' });
  }
  catch (error) {
    // Ignore - device not granting permissions
  }

  // Set to object for rendering
  devices.value = {
    camera: cameras,
    microphone: microphones,
    speaker: speakers
  };
}

// Look for previous device selections in localStorage
function checkDefaultDevices() {
  checkDefaultDevice('camera');
  checkDefaultDevice('microphone');
  checkDefaultDevice('speaker');
}

// Look for local storage value to override active device selection
function checkDefaultDevice(key) {
  var deviceId = localStorage[key];

  if (deviceId) {
    setActiveDevice(key, deviceId, true);
  }
}

function setActiveInputDevices(inputDevices) {
  setActiveDevice('camera', inputDevices.camera.deviceId);
  setActiveDevice('microphone', inputDevices.mic.deviceId);
  setActiveDevice('speaker', inputDevices.speaker.deviceId);
}

function setActiveDevice(key, deviceId, updateSystem = false) {
  var device = getDevice(key, deviceId),
    newDevices = _.clone(devices.value);  // Needed for reactivity

  if (device && !device.active) {
    newDevices[key].forEach((d) => { d.active = d.deviceId === deviceId });
    _.orderBy(newDevices[key], [function (d) { return !d.active ? true : false }]);
    devices.value = newDevices;
    if (updateSystem) {
      onDeviceChange(key, deviceId);
    }
  }

  return false;
}

function getDevice(key, deviceId) {
  return _.find(devices.value[key], (d) => { return d.deviceId === deviceId });
}

async function recordLessonEvent(type, params = {}) {
  var data;
  params.device = navigator.userAgent;

  try {
    if (!isTest()) {
      data = {
        type: type,
        lessonUuid: lessonUuid.value,
        teacherUuid: params.teacherUuid || teacherUuid.value,
        studentUuid: params.studentUuid || studentUuid.value,
        data: params,
        sessionId: params.sessionId || getLocalParticipant()?.session_id
      };

      await apiHelpers.post(`sync/lessonEvent`, data);
    }
  }
  catch (error) {
    logger.error(error, `Couldn't record lesson event ${type} with params: ${JSON.stringify(params)}`);
  }
}

// EVENT HANDLERS

async function onScreenShareEnded() {
  await stopScreenShare();
  setTimeout(() => {
    updateCallPerformanceSettings(callStatus.status.mode);
  }, 1000);
}

async function onScreenShareStarted() {
  setTimeout(() => {
    updateCallPerformanceSettings(callStatus.status.mode);
  }, 1000);
}

async function onCameraChange(deviceId) {
  setActiveDevice('camera', deviceId, true);
}

async function onMicrophoneChange(deviceId) {
  setActiveDevice('microphone', deviceId, true);
}

async function onSpeakerChange(deviceId) {
  setActiveDevice('speaker', deviceId, true);
}

async function onDeviceChange(key, deviceId) {
  var params,
    isInput,
    newDevices;

  localStorage[key] = deviceId;

  switch (key) {
    case 'camera':
      params = { videoDeviceId: deviceId };
      isInput = true;
      break;
    case 'microphone':
      params = { audioDeviceId: deviceId };
      isInput = true;
      break;
    case 'speaker':
      params = { outputDeviceId: deviceId };
      isInput = false;
      break;
  }

  newDevices = isInput ? await call.setInputDevicesAsync(params) : await call.setOutputDeviceAsync(params);

  setActiveInputDevices(newDevices);
}

// Toggle local microphone in use (on/off)
function onAudioClick() {
  const audioOn = call.localAudio();
  call.setLocalAudio(!audioOn);
  recordLessonEvent(audioOn ? 'mic on' : 'mic off')
}

// Toggle local camera in use (on/off)
function onVideoClick() {
  const videoOn = call.localVideo();
  call.setLocalVideo(!videoOn);
  recordLessonEvent(videoOn ? 'camera on' : 'camera off')
}

function onParticipantMuteClick(participant) {
  if (participant.local || participant.audio) {
    updateParticipant(participant, { setAudio: !participant.audio });
  }
  else {
    modalMessage.value.show({
      message: `For privacy reasons, you can't unmute a user`,
      buttons: [{ label: 'OK' }]
    });
  }
}

function onParticipantHideClick(participant) {
  if (participant.video) {
    updateParticipant(participant, { setVideo: false });
  }
  else {
    modalMessage.value.show({
      message: `For privacy reasons, you can't turn on a user's camera`,
      buttons: [{ label: 'OK' }]
    });
  }
}

function onAdmitClick(participant) {
  call.updateWaitingParticipant(participant.id, { grantRequestedAccess: true });
}

function onDenyClick(participant) {
  call.updateWaitingParticipant(participant.id, { grantRequestedAccess: false });
}

async function onScreenShareClick() {
  if (isScreensharing()) {
    stopScreenShare();
  } else {
    await startScreenShare();
  }
}

function onParticipantLeft(params) {
  var data;

  // Remove the participant from the participants array
  _.remove(participants.value, (p) => p.session_id === params.participant.session_id);

  checkTeacherIsPresent();

  data = {
    sessionId: params.participant.session_id,
    studentUuid: params.participant.userData.studentUuid,
    teacherUuid: params.participant.userData.teacherUuid
  };
  recordLessonEvent('left', data);
}

function checkTeacherIsPresent() {
  var teachers;

  teachers = getTeachers();

  if (!teachers || teachers.length === 0) {
    leaveCall('noTeacher');
  }
}

function getTeachers() {
  return _.find(call.participants(), (p) => {
    return p.permissions?.canAdmin ? true : false;
  });
}

function onWaitingParticipantsUpdated() {
  var newWaiting = Object.values(call.waitingParticipants());

  newWaiting.forEach((p) => {
    p.isWaiting = true;
    p.user_name = p.name
  });

  waitingParticipants.value = newWaiting;
}

function onLeaveClick() {
  leaveCall('left');
}

function onEmojiClick(key) {
  setUserData('emoji', key);

  if (emojiTimeout) {
    clearTimeout(emojiTimeout);
  }

  emojiTimeout = setTimeout(() => {
    removeUserData('emoji');
  }, 5000);

  recordLessonEvent('emoji', { type: key });
}

function onEjectClick(participant) {
  updateParticipant(participant, { eject: true });
}

function onHandClick() {
  var data;

  data = getUserData();

  if (data?.hand === true) {
    dismissHand();
  }
  else {
    raiseHand();
  }
}

function dismissHand() {
  setUserData('hand', false);
  recordLessonEvent('dismissHand');
}

function raiseHand() {
  setUserData('hand', true);
}

function onDismissHand(participant) {
  if (participant.local) {
    dismissHand();
  }
  else {
    sendAppMessage('dismissHand', null, participant);
  }
}

function onToggleBackground(participant) {
  if (participant.local) {
    toggleBackground();
  }
  else {
    sendAppMessage('toggleBackground', null, participant);
  }
}

function onCpuLoadChanged(data) {
  callStatus.handleStatusEvent('cpu', data.cpuLoadState);
}

function onNetworkQualityChanged(data) {
  var ourThreshold;

  // Use both Daily's threshold and quality number to determine our own level
  if (data.threshold === 'very-low' || data.quality < 40) {
    ourThreshold = 'very-low';
  }
  else if (data.threshold === 'low' || data.quality < 60) {
    ourThreshold = 'low';
  }
  else {
    ourThreshold = 'good';
  }

  callStatus.handleStatusEvent('network', ourThreshold);
}

async function updateSendSettings(settings) {
  await call.updateSendSettings(settings);
}

async function updateReceiveSettings(settings) {
  // If in a call
  if (['active', 'lobby'].includes(callState.value) && hasAccessLevel('full')) {
    call.updateReceiveSettings(settings);
  }
}

function handleCallStatusChange(data) {
  updateCallPerformanceSettings(data.mode);
}


async function updateCallPerformanceSettings(mode) {
  var presets,
    sendQuality,
    receiveLayers;

  switch (mode) {
    case 'bad':
      //presets = { video: 'bandwidth-optimized', screenVideo: 'detail-optimized' };
      sendQuality = defaultSendQuality - 2;
      receiveLayers = defaultReceiveLayers - 2;
      break;
    case 'ok':
      sendQuality = defaultSendQuality - 1;
      receiveLayers = defaultReceiveLayers - 1;
      break;
    case 'good':
      sendQuality = defaultSendQuality;
      receiveLayers = defaultReceiveLayers;
    default:
      presets = { video: 'bandwidth-optimized', screenVideo: 'detail-optimized' };
      //  presets = { video: 'default-video', screenVideo: 'motion-and-detail-balanced' };
      sendQuality = defaultSendQuality;
      receiveLayers = defaultReceiveLayers;
      break;
  }

  // Reduce send quality when also sending a screenshare video
  if (isScreensharing()) {
    sendQuality--;
  }

  if (!isTeacher.value) {
    if (screenShareSettings.value.pinned) {
      receiveLayers--;
    }
  }

  // // Reduce the receive quality for the teacher
  // // as they will have all of the screenshares coming in too
  // if (isTeacher.value === true) {
  //   receiveLayers--; 
  // }

  // Ensure quality/layers aren't below zero
  receiveLayers = Math.max(0, receiveLayers);
  sendQuality = Math.max(0, sendQuality);

  if (presets) {
    await updateSendSettings(presets);
  }

  await updateSendSettings({ video: { maxQuality: sendQualities[sendQuality] } });
  await updateReceiveSettings({ base: { video: { layer: receiveLayers } } });

  // Remove blur if CPU is high (students only)
  if (isTeacher.value === false && backgroundStatus.value !== 'disabled') {
    if (callStatus.status.cpu === 'high') {
      removeBackgroundEffects();
    }
  }

  setUserData('status', callStatus.status);
  recordLessonEvent('call-status', { mode: mode || 'default', status: callStatus.status });
}

function refreshParticipants(force) {
  var keys,
    callParticipants;

  if (call) {
    if (force || !participants.value) {
      participants.value = [];
      callParticipants = call.participants();
      keys = Object.keys(callParticipants);

      // callParticipants has sessionId as key
      // Convert that into a standard array for our purposes
      for (var key of keys) {
        participants.value.push(callParticipants[key]);
      }
    }
  }
}

async function startScreenShare() {
  call.startScreenShare();
}

async function stopScreenShare() {
  if (hasAccessLevel('full')) {
    await call.stopScreenShare();
  }
}

function onStartRecording() {
  //console.log('Recording started');
}

function handleRecordingError(error) {
  logger.error(error?.errorMsg, `Couldn't start recording for lesson UUID ${lessonUuid.value}`);
}

function onLeftMeeting() {
  if (!['ended', 'denied'].includes(callState.value)) {
    leaveCall('ejected');
  }
}

function onNonFatalError(error) {
  switch (error.type) {
    case 'screen-share-error':
      handleScreenshareError(error);
      break;
  }

  recordLessonEvent('non-fatal-error', error);
}

function handleScreenshareError(error) {
  var message = `Your device is preventing screen sharing in the classroom.<br><br>Please refer to your browser or operating system settings to enable screen sharing.`;

  if (error?.details?.category === 'permissions') {
    switch (error.details?.blockedBy) {
      case 'browser':
        message = `Your web browser is preventing screen sharing in the classroom.<br><br>Please refer to your browser's settings to enable screen sharing.`;
        break;
      case 'os':
        message = `Your operating system is preventing screen sharing in the classroom.<br><br>Please refer to your operating system settings to enable screen sharing.`;
        break;
      default:
        message = `Your device is preventing screen sharing in the classroom.<br><br>Please refer to your browser or operating system settings to enable screen sharing.`;
        break;
    }
  }

  modalMessage.value.show({
    title: `Can't start screen sharing`,
    message: message,
    buttons: [{ label: 'OK' }]
  });
}

function onCallError(error) {
  var userType,
    uuid;

  if (error.errorMsg == 'Join request rejected') {
    onDeniedAccess();
  }
  else {
    if (studentUuid.value) {
      userType = 'student';
      uuid = studentUuid.value;
    }
    else {
      userType = 'teacher';
      uuid = teacherUuid.value;
    }

    logger.error(error.errorMsg, `Problem with call for ${userType} ${uuid} in lesson ${lessonUuid.value}. URL: ${roomUrl.value}`);
  }
}

async function runNetworkCheck(callback) {
  var videoTrack,
    testResults;
  try {
    videoTrack = getLocalParticipant().tracks.video.persistentTrack;
    testResults = await call.testPeerToPeerCallQuality({
      videoTrack: videoTrack,
      duration: 10,
    });

    callback(testResults);
  }
  catch (error) {
    callback({ result: 'failed' });
  }
}

function onChallengeChanged(data) {
  var params;

  params = {
    type: data.type,
    achieved: data.achieved,
    studentUuid: data.participant.userData?.studentUuid
  }

  recordLessonEvent('challengeChanged', params);
}

</script>